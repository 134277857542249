/* eslint-disable */
var hexcase = 0 /* hex output format. 0 - lowercase; 1 - uppercase        */
// var b64pad = '=' /* base-64 pad character. "=" for strict RFC compliance   */

// 生成BaiduRtc Token
export const genToken = (appId, appKey, roomName, userId) => {
  let version = '004'
  let gDays = 365 * 100 + 24
  let startTs = (Date.parse(new Date()) + '').substring(0, 10)
  let expiredTs = (Date.parse(new Date()) + gDays * 24 * 3600 * 1000 + '').substring(0, 10)

  // Cookies.set("appId", appId);
  // Cookies.set("appKey", appKey);
  // Cookies.set("roomName", roomName);
  // Cookies.set("userId", userId);

  let token = generateTokenInner(version, appId, appKey, roomName, userId, startTs, expiredTs)
  return token
}

function generateTokenInner(version, appId, appKey, roomName, userId, startTs, expiredTs) {
  let randomStr = makeid(8)
  let signature = generateSignature(appId, appKey, startTs, randomStr, roomName, userId, expiredTs)
  let token = version.concat(signature, startTs, randomStr, expiredTs)
  return token
}

function generateSignature(appId, appKey, startTs, randomStr, roomName, userId, expiredTs) {
  let data = 'ACS'.concat(appId, startTs, randomStr, roomName, userId, expiredTs)
  let signature = hex_hmac_sha1(appKey, data)
  return signature
}

function makeid(length) {
  var result = ''
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

function hex_hmac_sha1(k, d) {
  return rstr2hex(rstr_hmac_sha1(str2rstr_utf8(k), str2rstr_utf8(d)))
}
/*
 * Convert a raw string to a hex string
 */
function rstr2hex(input) {
  var hex_tab = hexcase ? '0123456789ABCDEF' : '0123456789abcdef'
  var output = ''
  var x
  for (var i = 0; i < input.length; i++) {
    x = input.charCodeAt(i)
    output += hex_tab.charAt((x >>> 4) & 0x0f) + hex_tab.charAt(x & 0x0f)
  }
  return output
}

/*
 * Calculate the HMAC-SHA1 of a key and some data (raw strings)
 */
function rstr_hmac_sha1(key, data) {
  var bkey = rstr2binb(key)
  if (bkey.length > 16) bkey = binb_sha1(bkey, key.length * 8)

  var ipad = Array(16),
    opad = Array(16)
  for (var i = 0; i < 16; i++) {
    ipad[i] = bkey[i] ^ 0x36363636
    opad[i] = bkey[i] ^ 0x5c5c5c5c
  }

  var hash = binb_sha1(ipad.concat(rstr2binb(data)), 512 + data.length * 8)
  return binb2rstr(binb_sha1(opad.concat(hash), 512 + 160))
}

/*
 * Encode a string as utf-8.
 * For efficiency, this assumes the input is valid utf-16.
 */
function str2rstr_utf8(input) {
  var output = ''
  var i = -1
  var x, y

  while (++i < input.length) {
    /* Decode utf-16 surrogate pairs */
    x = input.charCodeAt(i)
    y = i + 1 < input.length ? input.charCodeAt(i + 1) : 0
    if (0xd800 <= x && x <= 0xdbff && 0xdc00 <= y && y <= 0xdfff) {
      x = 0x10000 + ((x & 0x03ff) << 10) + (y & 0x03ff)
      i++
    }

    /* Encode output as utf-8 */
    if (x <= 0x7f) output += String.fromCharCode(x)
    else if (x <= 0x7ff) output += String.fromCharCode(0xc0 | ((x >>> 6) & 0x1f), 0x80 | (x & 0x3f))
    else if (x <= 0xffff)
      output += String.fromCharCode(0xe0 | ((x >>> 12) & 0x0f), 0x80 | ((x >>> 6) & 0x3f), 0x80 | (x & 0x3f))
    else if (x <= 0x1fffff)
      output += String.fromCharCode(
        0xf0 | ((x >>> 18) & 0x07),
        0x80 | ((x >>> 12) & 0x3f),
        0x80 | ((x >>> 6) & 0x3f),
        0x80 | (x & 0x3f)
      )
  }
  return output
}

/*
 * Convert a raw string to an array of big-endian words
 * Characters >255 have their high-byte silently ignored.
 */
function rstr2binb(input) {
  var output = Array(input.length >> 2)
  for (var i = 0; i < output.length; i++) output[i] = 0
  for (var i = 0; i < input.length * 8; i += 8) output[i >> 5] |= (input.charCodeAt(i / 8) & 0xff) << (24 - (i % 32))
  return output
}

/*
 * Calculate the SHA-1 of an array of big-endian words, and a bit length
 */
function binb_sha1(x, len) {
  /* append padding */
  x[len >> 5] |= 0x80 << (24 - (len % 32))
  x[(((len + 64) >> 9) << 4) + 15] = len

  var w = Array(80)
  var a = 1732584193
  var b = -271733879
  var c = -1732584194
  var d = 271733878
  var e = -1009589776

  for (var i = 0; i < x.length; i += 16) {
    var olda = a
    var oldb = b
    var oldc = c
    var oldd = d
    var olde = e

    for (var j = 0; j < 80; j++) {
      if (j < 16) w[j] = x[i + j]
      else w[j] = bit_rol(w[j - 3] ^ w[j - 8] ^ w[j - 14] ^ w[j - 16], 1)
      var t = safe_add(safe_add(bit_rol(a, 5), sha1_ft(j, b, c, d)), safe_add(safe_add(e, w[j]), sha1_kt(j)))
      e = d
      d = c
      c = bit_rol(b, 30)
      b = a
      a = t
    }

    a = safe_add(a, olda)
    b = safe_add(b, oldb)
    c = safe_add(c, oldc)
    d = safe_add(d, oldd)
    e = safe_add(e, olde)
  }
  return Array(a, b, c, d, e)
}

/*
 * Add integers, wrapping at 2^32. This uses 16-bit operations internally
 * to work around bugs in some JS interpreters.
 */
function safe_add(x, y) {
  var lsw = (x & 0xffff) + (y & 0xffff)
  var msw = (x >> 16) + (y >> 16) + (lsw >> 16)
  return (msw << 16) | (lsw & 0xffff)
}

/*
 * Bitwise rotate a 32-bit number to the left.
 */
function bit_rol(num, cnt) {
  return (num << cnt) | (num >>> (32 - cnt))
}

/*
 * Perform the appropriate triplet combination function for the current
 * iteration
 */
function sha1_ft(t, b, c, d) {
  if (t < 20) return (b & c) | (~b & d)
  if (t < 40) return b ^ c ^ d
  if (t < 60) return (b & c) | (b & d) | (c & d)
  return b ^ c ^ d
}
/*
 * Determine the appropriate additive constant for the current iteration
 */
function sha1_kt(t) {
  return t < 20 ? 1518500249 : t < 40 ? 1859775393 : t < 60 ? -1894007588 : -899497514
}
/*
 * Convert an array of little-endian words to a string
 */
function binb2rstr(input) {
  var output = ''
  for (var i = 0; i < input.length * 32; i += 8)
    output += String.fromCharCode((input[i >> 5] >>> (24 - (i % 32))) & 0xff)
  return output
}
